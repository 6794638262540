// import 'core-js/es7/reflect';
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.
/***************************************************************************************************/
// import 'hammerjs';
// import 'ts-helpers';
import { environment } from './environments/environment';

if (environment.production) {
// // Production

} else {
// Development

  // BUG, this wi workaround for bug https://github.com/angular/angular/pull/23452
  // (window as any)['Zone'] = {
  //   current: {
  //     get: function() {}
  //   }
  // };

  // Error['stackTraceLimit'] = Infinity;
  console.log(JSON.stringify(environment));
  Error['stackTraceLimit'] = 5;
}
